.list-container {
    min-height: 440px;
    height: auto;
    position: absolute;
    top: 0; 
    bottom: 0;
}

.product {
    height: 65px;
}

.product > img {
    height: 80%;
    float: left;
}

.product > div {
    padding-left: 10px;
    vertical-align: top;
    line-height: 65px;
    font-size: 15px;
    float: left;
}

.product > div.price {
    float: right;
    font-size: 18px;
}