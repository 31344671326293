#btnKesinKayitYap{
    background-color:#ffc107;
}
#btnKesinKayitYap .dx-icon {
  color: white;
}

#btnSeciliOgrenciSms{
    background-color:orange;
}
#btnSeciliOgrenciSms .dx-icon {
  color: white;
}

#btnGorusmeler{
    background-color:#ffc107;
}
#btnGorusmeler .dx-icon {
  color: white;
}

#btnSozlesmeBas{
    background-color:#0d6efd;
}
#btnSozlesmeBas .dx-icon {
  color: white;
}

#btnYoklamaListesi{
    background-color:#0d6efd;
}
#btnYoklamaListesi .dx-icon {
  color: white;
}

#btnDanismanListesi{
    background-color:#0d6efd;
}
#btnDanismanListesi .dx-icon {
  color: white;
}

#btnKayitListesiExcel{
    background-color:#0d6efd;
}
#btnKayitListesiExcel .dx-icon {
  color: white;
}

#btnSubeDegistir{
    background-color:#0d6efd;
}
#btnSubeDegistir .dx-icon {
  color: white;
}

#btnDanismanDegistir{
    background-color:#198754;
} 
#btnDanismanDegistir .dx-icon {
  color: white;
}

#btnOgrenciEtutListesi{
    background-color:#198754;
} 
#btnOgrenciEtutListesi .dx-icon {
  color: white;
}